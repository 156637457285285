.user-challenges-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.challenge-list {
  list-style-type: none;
  padding: 0;
  width: 100%;
  max-width: 768px;
}

.challenge-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.challenge-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #000000; /* Fondo negro */
  padding: 10px;
  border-radius: 10px 10px 0 0;
}

.challenge-title {
  font-size: 28px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
  text-align: center;
}

.daily-button {
  background-color: #FFD400;
  color: #000000;
  font-weight: 900;
  font-size: 22px;
  text-transform: uppercase;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.challenge-completed {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  margin-bottom: 25px;
}

.challenge-completed span {
  color: #FFD400;
  font-weight: bold;
}

.challenge-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #333333;
  padding: 15px;
  border-radius: 0 0 10px 10px;
  margin-top: 50px;
}

.challenge-dates {
  font-size: 18px;
  color: #ffffff;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Espacio entre las líneas de "Inicio" y "Fin" */
}

.challenge-dates .label {
  font-weight: bold;
}

.challenge-dates .date {
  font-style: italic;
}

.challenge-day-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Alineación a la derecha */
}

.challenge-day {
  font-size: 18px;
  color: #FFD400;
  text-align: right;
  margin-bottom: 10px;
}

.challenge-day span {
  color: #ffffff;
  font-weight: bold;
}

.progress-button {
  background-color: #FFD400;
  color: #000000;
  font-weight: bold;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

/* Estilos para la sección de feedback */
.feedback-section {
  margin-top: 50px; /* Separación de 50px */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.feedback-text {
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 10px;
}

.finish-button {
  background-color: #000000;
  color: #FF0000; /* Texto en rojo */
  font-weight: bold;
  border: 3px solid #FFD400; /* Borde amarillo corporativo de 3px */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.quote {
  margin-top: 80px;
  font-size: 18px;
  text-align: center;
}

.quote-text {
  color: #ffffff;
  font-style: italic;
}

.quote-author {
  color: #FFD400;
  font-weight: bold;
  margin-top: 10px;
}
