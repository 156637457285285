/* Estilos para el contenedor del formulario */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }
  
  /* Estilo para los inputs de texto */
  .auth-container input[type="email"],
  .auth-container input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #FFD400;
    background-color: #000000;
    color: #ffffff;
    border-radius: 5px;
    font-size: 16px;
  }
  
  /* Estilo para el checkbox */
  .auth-container label {
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 20px; /* Añadir espacio debajo del checkbox */
    display: flex;
    align-items: center;
  }
  
  /* Añadir margen entre el checkbox y el botón de iniciar sesión */
  .auth-container label input[type="checkbox"] {
    margin-right: 10px;
  }
  
  /* Estilo para los botones */
  .auth-container button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #FFD400;
    color: #000000;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Estilo para el mensaje de error */
  .error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px; /* Añadir espacio entre el mensaje de error y el campo de contraseña */
    text-align: center;
  }
  