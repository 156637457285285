.feedback-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    color: #ffffff;
    background-color: #1a1a1a; /* Fondo oscuro para la página */
    min-height: 100vh;
  }
  
  .feedback-intro {
    font-size: 18px;
    text-align: center;
    margin-bottom: 30px;
    max-width: 800px;
    line-height: 1.5;
  }
  
  .feedback-intro span {
    color: #ffd400;
    font-weight: bold;
  }
  
  .feedback-form {
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
  }
  
  .feedback-form .question {
    margin-bottom: 20px;
  }
  
  .feedback-form label {
    font-weight: bold;
    margin-bottom: 10px;
    display: block;
  }
  
  .feedback-form select,
  .feedback-form input[type="number"] {
    width: 150px;
    padding: 8px;
    border-radius: 5px;
    border: 2px solid #ffd400;
    background-color: #2a2a2a;
    color: #ffffff;
    font-size: 16px;
    margin-top: 5px;
  }
  
  .input-with-symbol {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }
  
  .input-with-symbol span {
    font-size: 18px;
    margin-right: 5px;
    color: #ffd400;
  }
  
  .input-with-symbol input {
    width: 100px;
    padding: 8px;
    border-radius: 5px;
    border: 2px solid #ffd400;
    background-color: #2a2a2a;
    color: #ffffff;
    font-size: 16px;
  }
  
  .radio-group,
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
  }
  
  .radio-group label,
  .checkbox-group label {
    display: flex;
    align-items: center;
    font-weight: normal;
  }
  
  .radio-group input,
  .checkbox-group input {
    margin-right: 8px;
    accent-color: #ffd400;
    width: 18px;
    height: 18px;
  }
  
  .submit-button {
    background-color: #ffd400;
    color: #000000;
    font-weight: bold;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 30px;
    align-self: center;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #e5bf00;
  }
  
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #2a2a2a;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
    z-index: 1000;
    text-align: center;
    color: #ffffff;
    max-width: 500px;
    width: 90%;
  }
  
  .popup p {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .popup button {
    background-color: #ffd400;
    color: #000000;
    font-weight: bold;
    border: none;
    padding: 12px 24px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .popup button:hover {
    background-color: #e5bf00;
  }
  
  .value-note {
    font-style: italic;
    color: #FFD400;
    margin-top: 5px;
    font-size: 14px;
  }
  
  .return-button {
    background-color: #000000;
    color: #FFD400;
    font-weight: bold;
    border: 3px solid #FFD400;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 40px; /* Espacio de 40px respecto al botón anterior */
    margin-bottom: 20px;
    width: auto; /* Evita que ocupe todo el ancho */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.feedback-form textarea {
    width: 100%;
    border-radius: 5px;
    border: 2px solid #ffd400;
    background-color: #2a2a2a;
    color: #ffffff;
    font-size: 16px;
    resize: none;
    margin-top: 5px;
}
