/* Estilos generales para el contenedor del reto diario */
.daily-challenge-container {
    padding: 20px;
    text-align: center;
    position: relative;
    z-index: 1;
  }
  
  /* Estilo para la capa de superposición (overlay) */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Oscurecer la pantalla */
    z-index: 999; /* Asegura que el overlay esté sobre la página */
  }
  
  /* Estilos para el popup */
  .popup {
    background-color: #333333;
    color: #ffffff;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; /* Asegura que el popup esté sobre el overlay */
  }
  
  /* Estilos para los botones del popup */
  .complete-popup-button {
    background-color: #FFD400;
    color: #000000;
    font-weight: 800;
    text-transform: uppercase;
    border: none;
    padding: 10px 20px;
    margin: 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .cancel-popup-button {
    background-color: #555555;
    color: #ffffff;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    margin: 15px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  /* Estilos para el título del reto */
  .challenge-title {
    font-size: 36px;
    font-weight: bold;
    color: #FFD400;
    margin-bottom: 20px;
  }
  
  /* Estilos para la descripción del reto */
  .challenge-description {
    font-size: 18px;
    text-align: left;
    color: #ffffff;
    margin-bottom: 40px;
  }
  
  /* Estilos para los botones principales */
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  
  .complete-button {
    background-color: #FFD400;
    color: #000000;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 22px;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .back-button {
    background-color: #FFD400;
    color: #000000;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
  }
  