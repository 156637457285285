/* Establecer los estilos globales */
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  background-color: #000000;
  color: #ffffff;
}

/* Estilos para los títulos */
h1, h2, h3 {
  color: #FFD400;
}

/* Botones */
button {
  background-color: #FFD400;
  color: #000000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #FFC107;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .App {
    padding: 15px;
  }
}

/* Limitar el ancho del contenedor y centrarlo */
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;  /* Asegura que ocupe todo el ancho disponible */
  max-width: 768px;
  min-height: 100vh;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0;
  background-color: #000000;
}

.app-content {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 0;
}

/* Estilo para la imagen del logo */
.App-logo {
  max-width: 200px;  /* Ajusta el tamaño máximo según tus necesidades */
  margin: 20px auto; /* Centra la imagen horizontalmente */
  display: block;
  margin-bottom: 50px;
}

/* Estilo para el encabezado */
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
}

/* Estilo para la pantalla de inicio de sesión */
.login-page .App-header {
  justify-content: center !important;
}

/* Estilo para el resto de la aplicación */
.App-header:not(.login-page .App-header) {
  justify-content: space-between;
}

/* Estilo para la parte izquierda del encabezado (logo) */
.header-left {
  display: flex;
  align-items: center;
}

/* Estilo para la parte derecha del encabezado (usuario y botón de cerrar sesión) */
.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 20px; /* Ajuste de margen derecho si es necesario */
}

.header-right p {
  margin: 0 0 10px 0; /* Añade un margen inferior para separar del botón */
  font-weight: normal;
  color: #ffffff;
}

/* Estilo para el botón de cerrar sesión */
.logout-button {
  background-color: #FFD400;
  color: #000000;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 5px; /* Espacio entre el texto del usuario y el botón */
}

.logout-button:hover {
  background-color: #FFC107;
}

.app-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido horizontalmente */
  justify-content: flex-start; /* Alinea el contenido hacia arriba */
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 20px 0; /* Espacio en la parte superior e inferior */
  box-sizing: border-box;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    align-items: center;
  }

  .header-left img {
    max-width: 150px;
    margin-bottom: 30px;
  }

  .header-right {
    margin-right: 0px;
    align-items: center;
  }

  .header-right p {
    margin-bottom: 10px;
    text-align: center;
  }

  .logout-button {
    margin-top: 0; /* Elimina el margen superior si no es necesario */
  }
}

.beta-notice {
  font-size: 12px;
  color: #ffffff;
  line-height: 1.5;
  font-style: italic;
}

.beta-notice a {
  color: #FFD400;
  text-decoration: none;
  font-weight: bold;
}

.beta-notice a:hover {
  text-decoration: underline;
}