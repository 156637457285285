.challenge-progress-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.challenge-title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color: #FFD400;
    margin-bottom: 20px;
}

.progress-title {
    font-size: 26px;
    color: #FFFFFF;
}

.statistics-container {
    background-color: #333333;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 768px;
}

.task-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 768px;
}

.task-grid.stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    width: 100%;
    max-width: 768px;
}

.stat-box {
    background-color: #444444;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    color: #FFFFFF;
}

.completed {
    color: #00FF00;
    font-weight: bold;
    font-size: 18px;
}

.failed {
    color: #FF0000;
    font-weight: bold;
    font-size: 18px;
}

.remaining {
    color: #FFD400;
    font-weight: bold;
    font-size: 18px;
}

.progress-total {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    margin-top: 20px;
}

.progress-percentage {
    font-size: 28px;
    color: #FFD400;
    font-weight: bold;
}

.return-button {
    background-color: #FFD400;
    color: #000000;
    font-weight: bold;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.return-button.bottom {
    margin-top: 40px;
    margin-bottom: 40px;
}

.tasks-spacing {
    margin-bottom: 40px;
}

.task-item {
    background-color: #333333;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-info {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.task-day {
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
}

.task-title {
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 5px;
}

.task-status {
    font-size: 24px;
    font-weight: bold;
}

.status-icon.completed {
    color: #00FF00;
}

.status-icon.failed {
    color: #FF0000;
}

.status-icon.remaining {
    color: #FFD400;
}
